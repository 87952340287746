<template>
  <div>
    <v-container>
      <v-card class="pa-10 mt-10" v-if="ready">
        <v-card-text>
          <v-row class="mt-3" v-if="ready">
            <v-col cols="">
              <div>
                <v-row>
                  <v-col cols="">
                    <h1 class="mb-4">{{ customer.customer_name }}</h1>
                    <P
                      >Account ID: {{ customer.id }} | Account Code:
                      {{ customer.customer_code }}</P
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>

                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <p class="mt-3">
                      <v-icon class="mr-2">mdi-phone</v-icon>Phone Number:
                      <span class="green--text">{{
                        customer.customer_contact_no
                      }}</span>
                    </p>
                    <p>
                      <v-icon class="mr-2">mdi-facebook-messenger</v-icon> Other
                      Contact Plaftform: {{ customer.other_contact_platform }}
                    </p>
                    <p>
                      <v-icon class="mr-2">mdi-city</v-icon>Municipality:
                      {{ customer.customer_municipality }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="mt-3">
                      <v-icon class="mr-2">mdi-map-marker</v-icon>Barangay:
                      {{ customer.customer_barangay }}
                    </p>
                    <p>
                      <v-icon class="mr-2">mdi-map-marker</v-icon>Purok:
                      {{ customer.customer_purok }}
                    </p>
                    <p>
                      <v-icon class="mr-2">mdi-cloud-question-outline</v-icon
                      >Where did you find us:
                      {{ customer.where_did_you_find_us }}
                    </p>
                    <p>
                      <v-icon class="mr-2">mdi-calendar</v-icon>Date Created:
                      {{ customer.created_at }}
                    </p></v-col
                  >
                </v-row>
              </div></v-col
            >
          </v-row>
        </v-card-text>
        <!-- lazy loaded components -->
        <div v-if="(this.ready = true)">
          <UnitTable :customer_id="customer.id" />
          <InvoiceCard :customer_id="customer.id" class="mt-4" />
        </div>
      </v-card>
      <div v-else>
        <h2>Data is loading..</h2>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InvoiceCard: () => import("@/components/Payment/invoiceCard.vue"),
    UnitTable: () => import("@/components/Unit/unitTable.vue"),
  },
  data() {
    return {
      loading_dialog: false,
      loading_data: "",

      show_edit_profile: false,
      show_add_invoice: false,

      show_unit: false,
      show_invoice_card: false,

      loading: false,
      headers: [
        { text: "", value: "actions", width: 100 },
        { text: "id", value: "id" },
        { text: "Unit Type", value: "unit_type" },
        { text: "Unit Brand", value: "unit_brand" },
        { text: "Unit Model", value: "unit_model" },
        { text: "Serial Number", value: "serial_no" },
        { text: "Date Received", value: "date_received" },
        { text: "Picked Up Date", value: "picked_up_date" },
        { text: "Picked Up By", value: "picked_up_by" },
      ],
      ready: false,
      dialog: true,
    };
  },

  methods: {
    ...mapActions({
      get_units: "unit/get_units",
      get_services: "service/get_services",
      get_items: "item/get_items",
      get_customer: "customer/get_customer",
    }),

    async get_data() {
      this.loading_dialog = true;
      if (!this.services) {
        this.loading_data = "Services";

        await this.get_services();
      }
      if (!this.items) {
        this.loading_data = "Items";
        this.loading_dialog = true;
        await this.get_items();
      }
      this.loading_dialog = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      units: "unit/units",
      services: "service/services",
      items: "item/items",
      customer: "customer/customer",
    }),
  },
  async created() {
    // get customer
    // alert(this.$route.params.account_id);
    this.loading = false;
    this.get_customer(this.$route.params.account_id).then(() => {
      if (!this.items || !this.services) {
        this.get_data().catch((e) => {
          alert(e);
        });
      }
      this.ready = true;
    });
  },
};
</script>

<style lang="scss" scoped></style>
